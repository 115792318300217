exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("./../../../src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact-thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-social-projects-js": () => import("./../../../src/pages/social-projects.js" /* webpackChunkName: "component---src-pages-social-projects-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-therapies-js": () => import("./../../../src/pages/therapies.js" /* webpackChunkName: "component---src-pages-therapies-js" */),
  "component---src-pages-wellbeing-js": () => import("./../../../src/pages/wellbeing.js" /* webpackChunkName: "component---src-pages-wellbeing-js" */),
  "component---src-pages-wellness-js": () => import("./../../../src/pages/wellness.js" /* webpackChunkName: "component---src-pages-wellness-js" */)
}

